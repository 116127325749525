<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.61011 8.38848C7.06856 7.84642 6.62103 7.26069 6.38355 6.78618C6.30558 6.63037 6.25708 6.49967 6.23578 6.40623L6.71611 5.92561C6.82148 5.82015 6.87213 5.76881 6.93761 5.7C7.07631 5.55423 7.19195 5.42102 7.29368 5.28393C7.75135 4.66719 7.92143 3.9958 7.37967 3.39123C6.35391 1.95638 5.66385 1.06892 5.22143 0.631902C4.30856 -0.269819 2.82132 -0.14902 2.02965 0.631012C1.64738 1.00766 1.51744 1.13753 1.12492 1.5362C-1.16743 3.82966 0.199732 8.57107 3.81206 12.1868C7.42377 15.8019 12.1655 17.1699 14.464 14.8703C14.8248 14.5225 15.1331 14.214 15.3771 13.9562C16.1494 13.1399 16.266 11.7168 15.3712 10.787C14.9457 10.3449 14.0995 9.68827 12.6028 8.61748C12.0553 8.13212 11.415 8.23741 10.8461 8.61061C10.5898 8.77872 10.4096 8.94313 10.0701 9.28294L9.59022 9.76302C9.4969 9.74172 9.36638 9.69326 9.21082 9.61536C8.73652 9.37783 8.15126 8.93014 7.61011 8.38848ZM10.888 11.2925L11.484 10.6962C11.5858 10.5943 11.6663 10.5157 11.7329 10.454C12.9396 11.3239 13.6458 11.8771 13.931 12.1735C14.028 12.2742 14.0101 12.4923 13.9251 12.5821C13.7045 12.8152 13.4169 13.103 13.0633 13.4441C11.7585 14.7492 8.16102 13.7114 5.22611 10.7737C2.29008 7.83491 1.25247 4.2364 2.54396 2.94426C2.93445 2.54769 3.05726 2.42494 3.43253 2.0552C3.49211 1.99649 3.738 1.97652 3.81674 2.05431C4.12478 2.35859 4.70156 3.09371 5.54461 4.26293C5.52731 4.28177 5.50896 4.30138 5.48955 4.32178C5.43885 4.37506 5.39613 4.41836 5.30206 4.51252L4.70644 5.10845C3.55963 6.25511 4.46968 8.07358 6.19606 9.80158C7.92145 11.5286 9.74117 12.4399 10.888 11.2925Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
