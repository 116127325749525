<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_375_434)">
      <path
        d="M15.9693 3.69434L10.3573 9.30634C9.73157 9.93051 8.88382 10.281 7.99999 10.281C7.11616 10.281 6.26841 9.93051 5.64266 9.30634L0.030659 3.69434C0.0213257 3.79967 -7.62939e-06 3.895 -7.62939e-06 3.99967V11.9997C0.00105094 12.8834 0.35258 13.7306 0.977472 14.3555C1.60236 14.9804 2.4496 15.3319 3.33333 15.333H12.6667C13.5504 15.3319 14.3976 14.9804 15.0225 14.3555C15.6474 13.7306 15.9989 12.8834 16 11.9997V3.99967C16 3.895 15.9787 3.79967 15.9693 3.69434Z"
      />
      <path
        d="M9.41469 8.36384L15.504 2.27384C15.209 1.78472 14.793 1.37986 14.296 1.09832C13.799 0.816786 13.2379 0.668064 12.6667 0.666504H3.33336C2.76217 0.668064 2.20103 0.816786 1.70404 1.09832C1.20706 1.37986 0.791011 1.78472 0.496025 2.27384L6.58536 8.36384C6.96107 8.73805 7.46975 8.94815 8.00003 8.94815C8.5303 8.94815 9.03898 8.73805 9.41469 8.36384Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_375_434">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
