<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_174_5450)">
      <path
        d="M8 4.00049H4C2.93913 4.00049 1.92172 4.42192 1.17157 5.17206C0.421427 5.92221 0 6.93962 0 8.00049L0 12.0005C0 12.5309 0.210714 13.0396 0.585786 13.4147C0.960859 13.7898 1.46957 14.0005 2 14.0005H7C6.99841 15.3261 6.47112 16.5969 5.53378 17.5343C4.59644 18.4716 3.32559 18.9989 2 19.0005C1.60218 19.0005 1.22064 19.1585 0.93934 19.4398C0.658035 19.7211 0.5 20.1027 0.5 20.5005C0.5 20.8983 0.658035 21.2798 0.93934 21.5611C1.22064 21.8425 1.60218 22.0005 2 22.0005C4.121 21.9981 6.15445 21.1545 7.65422 19.6547C9.154 18.1549 9.99762 16.1215 10 14.0005V6.00049C10 5.47006 9.78929 4.96135 9.41421 4.58627C9.03914 4.2112 8.53043 4.00049 8 4.00049Z"
      />
      <path
        d="M21.9999 4.00049H17.9999C16.9391 4.00049 15.9217 4.42192 15.1715 5.17206C14.4214 5.92221 13.9999 6.93962 13.9999 8.00049V12.0005C13.9999 12.5309 14.2107 13.0396 14.5857 13.4147C14.9608 13.7898 15.4695 14.0005 15.9999 14.0005H20.9999C20.9983 15.3261 20.471 16.5969 19.5337 17.5343C18.5964 18.4716 17.3255 18.9989 15.9999 19.0005C15.6021 19.0005 15.2206 19.1585 14.9393 19.4398C14.658 19.7211 14.4999 20.1027 14.4999 20.5005C14.4999 20.8983 14.658 21.2798 14.9393 21.5611C15.2206 21.8425 15.6021 22.0005 15.9999 22.0005C18.1209 21.9981 20.1544 21.1545 21.6541 19.6547C23.1539 18.1549 23.9975 16.1215 23.9999 14.0005V6.00049C23.9999 5.47006 23.7892 4.96135 23.4141 4.58627C23.0391 4.2112 22.5303 4.00049 21.9999 4.00049Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_174_5450">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
